<template>
  <div
    id="MfrMarketSharePieChartWatchList"
  >
    <apex-chart
      :key="chartId"
      :height="$vuetify.breakpoint.smAndDown ? '300' : height"
      :options="MfrMarketSharePieChartWatchListOptions"
      :series="data.series"
    ></apex-chart>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiDotsVertical, mdiRefresh } from '@mdi/js'

// import { MfrMarketSharePieChartWatchListOptions } from '@/assets/chartOptions/market'
// import { apiService } from '@/api/index'
import themeConfig from '@themeConfig'
import { useFilterStore } from '@/stores/FilterStore'
import { useDataStore } from '@/stores/Data'
import { currencyConvert, getMfrNames, getMfrColors } from '@/util/functions'

export default {
    name: 'MfrMarketSharePieChartWatchList',
    components: {

    },
    props: {
        height: {
            type: String,
            default: 'auto',
        },
    },
    setup() {
        const themeColors = themeConfig.themes.light
        const dataStore = useDataStore()
        const filterStore = useFilterStore()

        return {
            getMfrColors,
            getMfrNames,
            themeColors,
            currencyConvert,
            dataStore,
            filterStore,
            icons: {
                mdiDotsVertical,
                mdiRefresh,
            },
        }
    },
    data() {
        return {
            filterStoreSubscribe: null,
            MfrMarketSharePieChartWatchListOptions: {
                title: {
                    text: 'Manufacturer Market Share',
                    style: {
                        fontSize: '16px',
                        fontWeight: 'bold',
                        color: this.themeColors.primary,
                    },
                },
                legend: {
                    show: false,
                },
                dataLabels: {
                    enabled: true,
                    offsetX: 30,
                    style: {
                        fontSize: '16px',

                    },
                    formatter(value) {
                        return `%${parseInt(value * 100, 10) / 100}`
                    },
                },
                tooltip: {

                    y: {
                        formatter(value) {
                            return currencyConvert(value)
                        },

                        // title: {
                        //     formatter(value) {
                        //         return getMfrNames(value)
                        //     }
                        // }
                    },

                    // custom({
                    //     series, seriesIndex, dataPointIndex, w
                    // }) {
                    //     console.log(currencyConvert(series[seriesIndex]))

                    //     return `${currencyConvert(series[seriesIndex])}`

                    //                     return `${'<div class="arrow_box">'
                    //   + '<span>'}${ series[seriesIndex][dataPointIndex] }</span>`
                    //   + '</div>'
                    // }
                },
                plotOptions: {
                    pie: {
                        startAngle: 0,
                        endAngle: 360,
                        expandOnClick: true,
                        offsetX: 0,
                        offsetY: 0,
                        dataLabels: {
                            offset: 0,
                            minAngleToShowLabel: 10
                        },
                        donut: {
                            size: '65%',
                            background: 'transparent',
                            labels: {
                                show: true,
                                name: {
                                    show: true,

                                    fontSize: '6px',

                                    color: this.themeColors.primary,

                                    formatter(val) {
                                        return getMfrNames(val)
                                    },
                                },
                                value: {
                                    show: true,
                                    fontSize: '12px',
                                    fontWeight: 'bold',
                                    color: undefined,
                                    offsetY: 16,
                                    formatter(val) {
                                        return currencyConvert(val)
                                    }
                                },
                                total: {
                                    show: true,
                                    showAlways: false,
                                    label: 'Total',
                                    fontSize: '12px',
                                    fontWeight: 'bold',
                                    color: this.themeColors.primary,
                                    formatter(w) {
                                        return currencyConvert(w.globals.seriesTotals.reduce((a, b) => a + b, 0))
                                    }
                                }
                            }
                        },
                    }
                },

                chart: {
                    width: '100%',
                    id: 'MfrMarketSharePieChartWatchList',
                    animations: {
                        enabled: true,
                        easing: 'easeinout',
                        speed: 800,
                        animateGradually: {
                            enabled: true,
                            delay: 150
                        },
                        dynamicAnimation: {
                            enabled: true,
                            speed: 350
                        }
                    },
                    toolbar: {
                        show: true,
                        offsetX: 0,
                        offsetY: 0,
                        tools: {
                            download: true,
                            selection: true,
                            zoom: true,
                            zoomin: true,
                            zoomout: true,
                            pan: true,
                            reset: true,
                            customIcons: [],
                        },
                        export: {
                            csv: {
                                filename: undefined,
                                columnDelimiter: ',',
                                headerCategory: 'category',
                                headerValue: 'value',
                                dateFormatter(timestamp) {
                                    return new Date(timestamp).toDateString()
                                },
                            },
                            svg: {
                                filename: undefined,
                            },
                            png: {
                                filename: undefined,
                            },
                        },
                        autoSelected: 'zoom',
                    },
                    brush: {
                        enabled: false,
                        target: undefined,
                        autoScaleYaxis: false
                    },
                    type: 'donut',
                    sparkline: {
                        enabled: false,
                    },
                    events: {
                        dataPointSelection: (event, chartContext, config) => {
                            this.$root.$emit('mfr-market-share-pie-chart', config)
                        },
                    },
                },
                noData: {
                    text: 'No Data',
                    align: 'center',
                    verticalAlign: 'middle',
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                        color: this.themeColors.primary,
                        fontSize: '20px',

                        //   fontFamily: undefined,
                    },
                },

                states: {
                    active: {
                        allowMultipleDataPointsSelection: false,
                        filter: {
                            type: 'darken',
                            value: 0.35,
                        },
                    },
                },
            },
            data: [],
            series: [],
            refresh: false,
            chartId: 0,
            test: []
        }
    },

    // watch: {
    //     $vuetify: {
    //         handler() {
    //             if (this.$vuetify.breakpoint.mdAndDown) {
    //                 this.MfrMarketSharePieChartWatchListOptions = {
    //                     ...this.MfrMarketSharePieChartWatchListOptions,
    //                     legend: {
    //                         show: false,
    //                     },
    //                 }
    //             } else {
    //                 this.MfrMarketSharePieChartWatchListOptions = {
    //                     ...this.MfrMarketSharePieChartWatchListOptions,
    //                     legend: {
    //                         show: true,
    //                     },
    //                 }
    //             }
    //         },

    //         immediate: true,

    //         deep: true,

    //     }
    // },
    destroyed() {
        // this.filterStoreSubscribe()
    },
    async mounted() {
        this.getMongoChartData()

        this.filterStoreSubscribe = this.filterStore.$onAction(async ({ name }) => {
            if (name === 'refreshCharts') {
                await this.dataStore.setDataObject(['MfrMarketSharePieChartWatchList'], {})
                await this.getMongoChartData()
            }
        })
    },
    methods: {
        async getMongoChartData() {
            if (this.dataStore.chartData.MfrMarketSharePieChartWatchList == null) {
                await this.dataStore.setDataObject(['MfrMarketSharePieChartWatchList'], {})
            }
            this.data = this.dataStore.chartData.MfrMarketSharePieChartWatchList.value
            this.MfrMarketSharePieChartWatchListOptions = {
                ...this.MfrMarketSharePieChartWatchListOptions,
                labels: this.data.labels,
                colors: await this.getMfrColors(this.data.labels)
            }
        },
    },
}
</script>

<style>
.apexcharts-canvas .apexcharts-pie .apexcharts-datalabel-label, .apexcharts-canvas .apexcharts-pie .apexcharts-datalabel-value {
    font-size: 1.0rem !important;
}
</style>
