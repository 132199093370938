<template>
  <div>
    <v-row class="tw-pb-3">
      <v-col
        v-if="$vuetify.breakpoint.smAndDown"
        cols="12"
        md="3"
        lg="3"
        sm="12"
        order="1"
      >
        <active-filter></active-filter>
      </v-col>
      <v-col
        cols="12"
        lg="4"
        md="4"
        sm="4"
        order="2"
      >
        <statistics-card-horizontal
          :stat-title="pharmacyGenericPotential.statTitle"
          :change="pharmacyGenericPotential.change"
          :color="pharmacyGenericPotential.color"
          :icon="pharmacyGenericPotential.icon"
          :statistics="dataStore.quickStats.totalGenericPotential.total"
          :currency="true"
        ></statistics-card-horizontal>
      </v-col>
      <v-col
        cols="12"
        md="4"
        lg="4"
        sm="4"
        order="3"
      >
        <statistics-card-horizontal
          :stat-title="pharmacyListStats.statTitle"
          :change="pharmacyListStats.change"
          :color="pharmacyListStats.color"
          :icon="pharmacyListStats.icon"
          :statistics="dataStore.quickStats.totalGenericPotential.taroSales"
          :currency="true"
        ></statistics-card-horizontal>
      </v-col>
      <v-col
        cols="12"
        md="4"
        lg="4"
        sm="4"
        order="4"
      >
        <statistics-card-horizontal
          :stat-title="taroMarketShare.statTitle"
          :change="taroMarketShare.change"
          :color="taroMarketShare.color"
          :icon="taroMarketShare.icon"
          :statistics="dataStore.quickStats.totalGenericPotential.taroMarketShare"
        ></statistics-card-horizontal>
      </v-col>
    </v-row>

    <v-card>
      <v-card-text>
        <!-- <v-card-title>
        <p color="primary">
          Pharmacy List
        </p>
        <v-spacer></v-spacer>
      </v-card-title> -->
        <v-row>
          <v-col
            cols="12"
            md="7"
            sm="12"
            order="6"
          >
            <div>
              <v-card flat>
                <v-card-text>
                  <v-row>
                    <v-col
                      cols="12"
                      offset-md="8"
                      md="4"
                    >
                      <v-text-field
                        v-model="search"
                        :append-icon="icons.mdiMagnify"
                        label="Search"
                        single-line
                        hide-details
                        dense
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <v-data-table
                v-if="!vuetify.breakpoint.smAndDown"
                v-model="filterStore.selectedPharmacy"
                :loading="pharmacyListStore.loading"
                :headers="pharmacyTableHeaders"
                :items="pharmacyListData"
                item-key="Accreditation_Number"
                class="table-rounded"
                sort-by="sales"
                sort-desc
                :height="$vuetify.breakpoint.smAndDown ? '100%' : height - 470"
                :search="search"
                :single-select="filterStore.singleMode"
                :items-per-page="rowsPerPage"
                :footer-props="{
                  'items-per-page-options': [5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 25, 50, 100, -1],
                }"
              >
                <template
                  v-for="header in pharmacyTableHeaders.filter(header => header.hasOwnProperty('formatter'))"
                  #[`item.${header.value}`]="{ value }"
                >
                  {{ header.formatter(value) }}
                </template>
                <template
                  #[`item.pharmacyName`]="{ item }"
                >
                  <template>
                    <v-btn
                      text
                      @click="goToPharmacyView(item)"
                    >
                      <span class="tw-underline">{{ item.pharmacyName }}</span>
                    </v-btn>
                  </template>
                </template>
              </v-data-table>
            </div>
            <v-list v-if="vuetify.breakpoint.smAndDown">
              <v-list-item
                v-for="item in pharmacyListData"
                :key="item.Accreditation_Number"
                @click="goToPharmacyView(item)"
              >
                <v-list-item-content>
                  <v-list-item-title class="tw-underline">
                    {{ item.pharmacyName }}
                  </v-list-item-title>
                  <v-list-item-subtitle>{{ currencyConvert(item.sales) }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>

          <v-col
            cols="12"
            md="5"
            sm="12"
            order="6"
          >
            <v-card
              :height="$vuetify.breakpoint.smAndDown ? '100%' : `${(height - 470).toString()}`"
              flat
            >
              <div>
                <mfr-market-share-pie-chart-watch-list
                  :height="$vuetify.breakpoint.smAndDown ? '100%' : `${(height - 470).toString()}`"
                >
                </mfr-market-share-pie-chart-watch-list>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-row>
      <v-col
        cols="12"
        md="12"
      >
        <stacked-time-seriesmonthly-mfr
          series="monthlyStackedSalesByMfrTar"
        >
        </stacked-time-seriesmonthly-mfr>
      </v-col>
    </v-row>
    <please-stand-by
      :loading="pharmacyListStore.loading"
    ></please-stand-by>
  </div>
</template>

<script>
import {
    mdiAccountOutline,
    mdiStorePlus,
    mdiCashMultiple,
    mdiStore,
    mdiFastForwardOutline,
    mdiSackPercent,
    mdiTrendingUp,
    mdiPoll,
    mdiCurrencyUsd,
    mdiAccountPlusOutline,
    mdiMagnify
} from '@mdi/js'
import { pharmacyTableHeaders } from '@/assets/headers/index'
import StatisticsCardHorizontal from '@/@core/components/statistics-card/StatisticsCardHorizontal.vue'
import { useFilterStore } from '@/stores/FilterStore'
import { currencyConvert, handleResize } from '@/util/functions'
import MfrMarketSharePieChartWatchList from '@/components/charts/MfrMarketSharePieChartWatchList.vue'

import { usePharmacyListStore } from '@/stores/PharmacyListStore'
import { useDataStore } from '@/stores/Data'
import { ref, watch } from 'vue'
import { getVuetify } from '@core/utils'
import { useWindowSize } from '@vueuse/core'
import activeFilter from '@/components/tables/activeFilter.vue'

export default {
    name: 'PharmacyList',
    components: {
        StatisticsCardHorizontal,
        MfrMarketSharePieChartWatchList,
        pleaseStandBy: () => import('@/components/dialog/pleaseStandBy.vue'),
        activeFilter,
        stackedTimeSeriesmonthlyMfr: () => import('@/views/molecule/stackedTimeSeriesmonthlyMfr.vue'),

        // activeFilter
    },
    setup() {
        const vuetify = getVuetify()
        const dataStore = useDataStore()
        const filterStore = useFilterStore()

        const pharmacyListStore = usePharmacyListStore()
        const { width, height } = useWindowSize()

        // const { width, height } = useWindowSize()
        const rowHeight = 50 // adjust this to the actual height of each row

        const pharmacyGenericPotential = {
            statTitle: 'Total Sales',
            statistics: '',
            change: '+',
            icon: mdiPoll,
            color: '#2196F3',
        }

        const pharmacyListStats = {
            statTitle: 'Taro Sales',
            statistics: '',
            change: '+',
            icon: mdiCurrencyUsd,
            color: '#4CAF50',
        }
        const averagePerPharmacy = {
            statTitle: 'Average Potential Per Pharmacy',
            statistics: '',
            change: '+',
            icon: mdiAccountPlusOutline,
            color: '#9C27B0',
        }

        const averageTaroSales = {
            statTitle: 'Average Taro Sales Per Pharmacy',
            statistics: '',
            change: '+',
            icon: mdiTrendingUp,
            color: '#FF9800',
        }

        const taroMarketShare = {
            statTitle: 'Market Share',
            statistics: '',
            change: '+',
            icon: mdiSackPercent,
            color: 'error',
        }
        const filterData = ref({})
        const search = ref('')

        return {
            filterData,
            currencyConvert,
            rowHeight,
            vuetify,
            width,
            height,
            dataStore,

            pharmacyListStore,
            filterStore,
            pharmacyGenericPotential,
            pharmacyListStats,
            averagePerPharmacy,
            taroMarketShare,
            handleResize,
            averageTaroSales,
            search,
        }
    },
    data() {
        return {
            showCard: false,
            filterStoreUnsubscribe: null,
            pharmacyTableHeaders,
            pharmacyListData: [],
            rowsPerPage: 10,
            icons: {
                mdiMagnify,
                mdiAccountOutline,
                mdiStorePlus,
                mdiCashMultiple,
                mdiStore,
                mdiFastForwardOutline,
                mdiSackPercent,
                mdiTrendingUp,
                mdiPoll,
                mdiCurrencyUsd,
                mdiAccountPlusOutline
            },
        }
    },
    computed: {
    },

    // watch: {
    //     search: {
    //         handler(val) {
    //             // search for the value of search in the table data and return the results
    //             this.pharmacyListData = this.pharmacyListStore.pharmacyList.filter(item => item.pharmacyName.toLowerCase().includes(val.toLowerCase()))
    //         },
    //         immediate: true,
    //     },
    // },
    created() {
        // window.addEventListener('resize', this.debouncedMatchHeight)
    },
    async mounted() {
        this.$root.$on('refresh-all-charts', async () => {
            this.getMongoChartData()
        })

        // this.filterStoreUnsubscribe = this.filterStore.$onAction(({ name, after }) => {
        //     after(() => {
        //         if (name === 'refreshCharts') {
        //             this.getMongoChartData()
        //         }
        //     })
        // })
        // if (this.pharmacyListStore.pharmacyList.length === 0) {
        //     await this.pharmacyListStore.setPharmacyList()
        // }

        this.getMongoChartData()
    },

    destroyed() {
        // window.removeEventListener('resize', this.debouncedMatchHeight)
        // this.filterStoreUnsubscribe()
        // this.$root.$off('refresh-all-charts')
    },
    methods: {
        searchTable(search) {
            this.search = search
        },
        filterByTarget(view) {
            console.log(view)
        },
        async goToPharmacyView(item) {
            this.filterStore.selectedPharmacy = [item]
            await this.dataStore.setPharmacyMoleculeList()
            this.$router.push({ name: 'pharmacy-molecule-list' })
        },

        async getMongoChartData() {
            this.pharmacyListData = await this.pharmacyListStore.pharmacyList.sort((a, b) => b.sales - a.sales)
        },
        getPharmacyGenericPotential() {
            return this.pharmacyListData.reduce((accumulator, object) => accumulator + object.sales, 0)
        },
        updateShowCardProp(showCard) {
            this.showCard = showCard
        },
    },
}
</script>
<style>
  .fit-within-viewport {
    max-width: 100%;
    margin: 0 auto;
  }
</style>
